<template>
<div class="main">
    <div class="container">
        <img :src = photo.path>
    </div>
    <div class="container" style="padding: 10px;">
        <span id="h1">{{ photo.title }}</span><br>
        <span id="h3">{{formatDate(photo.created)}}</span>
        <br><br>
        <span id="h2">{{ photo.description }}</span><br><br><br>
        <span id="h3">Uploaded by {{ photo.user.firstName }} {{ photo.user.lastName }}</span>
    </div>
    <div id="addComments" v-if="user"> 
        <span id="h2">Add a comment</span><br><br>
        <textarea v-model="comment"></textarea>
        <button @click="uploadComment()" type="submit">Post Comment</button>
    </div>
    <div id="comments">
        <div id="comment" v-for="comment in comments" v-bind:key="comment._id">
            <span id="h3">{{ comment.user.firstName }} {{ comment.user.lastName }}</span> 
            <span style="float: right;">{{ formatDate(comment.created) }}</span><br><br>
            {{ comment.comment }}
            
        </div>
    </div>
</div>    
</template>


<script>
import axios from 'axios';
import moment from 'moment';
export default {
    name: 'Photo',
    data() {
        return {
            photo: {},
            comments: [],
            comment: '',
            error: ''
        }
    },
    created() {
        this.getPhoto();
        this.getLoggedIn();
        this.getComments();
    },
    computed: {
        user() {
            return this.$root.$data.user;
        }
    },
    methods: {
        formatDate(date) {
            return moment(date).format('d MMMM YYYY');
        },
        async getPhoto() {
            try {
                let response = await axios.get("/api/photos/" + this.$route.params.id);
                this.photo = response.data;
            } catch (error) {
                this.error = error.response.data.message;
            }
        },
        async getLoggedIn() {
                try {
                    let response = await axios.get('/api/users');
                    this.$root.$data.user = response.data.user;
                } catch (error) {
                    this.$root.$data.user = null;
                }
        },
        async uploadComment() {
            try {
                await axios.post("/api/comments/" + this.$route.params.id, { comment: this.comment });
                this.comment = "";
                this.getComments();
            } catch (error) {
                this.error = "Error: " + error.response.data.message;
            }
        },
        async getComments() {
            try {
                this.response = await axios.get('/api/comments/' + this.$route.params.id);
                this.comments = this.response.data;
            } catch (error) {
                this.error = "Error: " + error.response.data.message;
            }
        }
    }
}
</script>

<style scoped>
.main {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding-top: 100px;
}
.container {
    object-fit: contain;
    overflow: hidden;
    margin: 0 auto;
    width: 44%;
}
#details {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
}
img {
    width: 100%;
    height: auto;
}

#h1, #h2, #h3 {
    color: black;
}

#h1 {
    font-size: 60px;
}

#h2 {
    font-size: 30px;
}

#h3 {
    font-size: 20px;
    padding-top: 15px;
}

#addComments{
    padding-top: 30px;
    margin-bottom: 30px;
    width: 100%
}

textarea {
    width: 100%;
    height: 90px;
}

#comments {
    flex-basis: 100%;
}

#comment {
    width: 100%;
    border: solid 2px lightgray;
    padding: 10px;
    margin: 5px;
}

@media all and (max-width: 950px) {
    .main {
        flex-flow: column nowrap;
    }
    .container {
        width: 100%;
    }
}


</style>
